<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showModal">
      <add-user @closeModal="showModal" />
    </mcp-modal>
    <mcp-modal :show="showEdit" @closeModal="showModalEdit">
      <update-user @closeModal="showModalEdit" :model="current" />
    </mcp-modal>
    <search :makeSearch="makeSearch">
      <template #buttons>
        <button @click="show = true" class="button is-outlined is-small">
          Ajouter un utilisateur
        </button>
        <button @click="refreshUsers" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="users.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(user, key) in users" :key="key">
            <td>
              <img class="avatar" :src="user.photo" alt="avatar" />
            </td>
            <td>{{ user.lastname }}</td>
            <td>{{ user.firstname }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone }}</td>
            <td>
              <span v-if="user.profile == '1'" class="tag is-success is-light"
                >Adminstrateur</span
              >
              <span v-if="user.profile == '0'" class="tag is-primary is-light">Client</span>
              <span v-if="user.profile == '2'" class="tag is-info is-light">Opérateur</span>
            </td>
            <td>{{ user.created_at }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Modifier">
                  <i class="fa-solid fa-edit" @click="showFormEdit(user)"></i>
                </mcp-tooltip>
                <mcp-tooltip title="Supprimer">
                  <i
                    v-if="user.id != isConnected"
                    @click="removeUser(user)"
                    class="fa-solid fa-remove"
                  ></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import mcpModal from "@/components/McpModal";
import addUser from "./add.vue";
import updateUser from "./edit.vue";
import { currentId } from "@/utils/Auth";
export default {
  name: "userList",
  components: {
    dataTable,
    search,
    mcpModal,
    addUser,
    updateUser,
  },
  data: () => ({
    loading: false,
    show: false,
    showEdit: false,
    isConnected: null,
    columns: [
      { name: "photo", label: "Avatar" },
      { name: "lastname", label: "Nom" },
      { name: "firstname", label: "Prénom" },
      { name: "email", label: "Email" },
      { name: "phone", label: "Téléphone" },
      { name: "profile", label: "Compte" },
      { name: "created_at", label: "Date de création" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      search: "",
    },
    current: {},
  }),

  computed: {
    ...mapGetters({
      users: `${TYPES.modules.USER}/${TYPES.getters.GET_USERS}`,
      meta: `${TYPES.modules.USER}/${TYPES.getters.GET_USERS_META}`,
    }),
  },

  mounted() {
    this.isConnected = currentId();
    this.getUsers();
  },

  methods: {
    navigationFunction(page) {
      this.model.page = page;
      this.getUsers();
    },

    getUsers() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.USER + "/" + TYPES.actions.GET_USERS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.page = 1;
      this.model.search = search;
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.USER + "/" + TYPES.actions.GET_USERS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshUsers() {
      this.loading = true;
      this.model.page = 1;
      this.model.search = "";
      this.$store
        .dispatch(
          `${TYPES.modules.USER + "/" + TYPES.actions.GET_USERS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeUser(user) {
      if (confirm(`Voulez vous supprimer l'utilisateur ?`)) {
        this.loading = true;
        this.$store
          .dispatch(
            `${TYPES.modules.USER + "/" + TYPES.actions.DELETE_USERS}`,
            user
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`Opération éffectuée`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    showModal() {
      this.show = !this.show;
    },

    showModalEdit() {
      this.showEdit = !this.showEdit;
    },

    showFormEdit(user) {
      this.current = user;
      this.showModalEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  height: 30px;
  width: 30px;
  border-radius: 100%;
}
</style>