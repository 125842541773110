<template>
  <div>
    <h2>Modifier un utilisateur</h2>
    <mcp-spinner v-if="loading" :show="true" color="#CF0C1A" />
    <div v-else>
      <div class="pt-10">
        <text-input
          type="text"
          :errors="errors.lastname"
          v-model="form.lastname"
          label="Nom"
        />
      </div>

      <div class="pt-10">
        <text-input
          type="text"
          :errors="errors.firstname"
          v-model="form.firstname"
          label="Prénom"
        />
      </div>

      <div class="pt-10">
        <text-input
          type="email"
          :errors="errors.email"
          v-model="form.email"
          label="Email"
        />
      </div>

      <div class="pt-10">
        <text-input
          type="number"
          :errors="errors.phone"
          v-model="form.phone"
          label="Téléphone"
        />
      </div>

      <div class="pt-10">
        <label>Type de compte</label>
        <v-select
          :class="errors.is_customer ? 'is-danger' : ''"
          :options="[
            { name: 'Administrateur', id: 1 },
            { name: 'Client', id: 0 },
            { name: 'Opérateur', id: 2 },
          ]"
          placeholder="Type de compte"
          v-model="form.is_customer"
          label="name"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.is_customer"
          :class="errors.is_customer.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.is_customer[0]
          }}</strong>
        </div>
      </div>

      <div class="pt-10" v-if="form.is_customer == '0'">
        <label>Client</label>
        <v-select
          :options="customers"
          :class="errors.mcp_id ? 'is-danger' : ''"
          placeholder="Selectionner un client"
          v-model="form.mcp_id"
          label="name"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.mcp_id"
          :class="errors.mcp_id.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.mcp_id[0]
          }}</strong>
        </div>
      </div>

      <div class="pt-10" v-if="form.is_customer == '2'">
        <label>Opérateur</label>
        <v-select
          :options="banks"
          :class="errors.bank ? 'is-danger' : ''"
          placeholder="Selectionner un opérateur"
          v-model="form.bank"
          label="code"
          :reduce="(item) => item.id"
        ></v-select>
        <div
          v-if="errors.bank"
          :class="errors.bank.length > 0 ? 'invalid-feedback' : ''"
        >
          <strong class="has-text-danger" style="font-size: 12px">{{
            errors.bank[0]
          }}</strong>
        </div>
      </div>

      <div class="pt-10">
        <label class="checkbox"
          ><input v-model="form.is_auto" type="checkbox" /> Voulez vous changer
          le mot de passe ?</label
        >
      </div>

      <div class="pt-10" v-if="form.is_auto == true">
        <text-input
          type="password"
          :errors="errors.password"
          v-model="form.password"
          label="Mot de passe"
        />
      </div>

      <div class="pt-10 d-flex-right">
        <button
          @click="updateUser"
          :disabled="loadingBtn"
          :class="
            loadingBtn
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";
export default {
  name: "editUser",
  components: { TextInput },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    loadingBtn: false,
    form: {
      id: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      is_customer: "0",
      is_auto: false,
      mcp_id: "",
      bank: ""
    },
    errors: [],
  }),

  watch: {
    "form.is_auto": function (val) {
      if (val == 0) {
        this.form.password = "";
      }
    },
    // "form.is_customer": function (val) {
    //   if (val == "0") {
    //     this.form.mcp_id = "";
    //   }
    // },
  },

  computed: {
    ...mapGetters({
      customers: `${TYPES.modules.UTILS}/${TYPES.getters.GET_MCP}`,
      banks: `${TYPES.modules.BANKS}/${TYPES.getters.GET_BANKS}`,
    }),
  },

  mounted() {
    const { id, mcp_id, firstname, lastname, email, phone, profile } = this.model;
    this.form.id = id;
    this.form.firstname = firstname;
    this.form.lastname = lastname;
    this.form.email = email;
    this.form.phone = phone;
    this.form.is_customer = profile
    if (profile == 0){
      this.form.mcp_id = mcp_id;
      this.getCustomers()
    }else if (profile == 2){
      this.form.bank = mcp_id;
      this.getBanks();
    }
  },

  methods: {
    getCustomers() {
      this.loading = true;
      this.$store
        .dispatch(`${TYPES.modules.UTILS + "/" + TYPES.actions.GET_MCP}`)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getBanks() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.GET_BANKS}`, {}
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    updateUser() {
      this.loadingBtn = true;
      this.$store
        .dispatch(`${TYPES.modules.USER + "/" + TYPES.actions.UPDATE_USERS}`, {
          form: this.form,
          model: this.model,
        })
        .then(() => {
          this.loadingBtn = false;
          this.$emit("closeModal");
          this.$toast.success(`Opération effectuée`);
        })
        .catch((error) => {
          this.loadingBtn = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>